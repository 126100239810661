import * as React from "react"
import Layout from "../components/layout"

const ContactUsPage = () => {
  return (
    <Layout>
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <h2 className={'text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold my-24 max-w-screen-lg'}>If you would like to commission a sculpture or a painting; please get in touch.</h2>
        <p className={'text-xl sm:text-2xl lg:text-3xl mb-16 max-w-screen-md'}>I would love to hear your ideas and create a really meaningful piece of art for you.</p>
        <strong className={'text-xl mb-2'}>Email address</strong><br />
        <a href="mailto:gill.morrow@hotmail.co.uk" className={'border-b-2 border-black hover:border-transparent transition py-1'}>gill.morrow@hotmail.co.uk</a>
      </main>
    </Layout>
  )
}

export default ContactUsPage

export const Head = () => (
    <>
    <title>Commission Me - Gill Morrow Art</title>
    <meta name="description" content="Contact me today to arrange a consultation. gill.morrow@hotmail.co.uk" />
    </>
)
